<template>
  <div class="container">
    <div class="car">
      <div class="car-title"><h3>购物车</h3></div>

      <div class="shopping-car">
        <a-spin :spinning="spinning">
          <div class="goods-box">
            <div class="top-title db-ml16">
              <a-checkbox v-model:checked="isAllCheck" :disabled="!getShoppingCart.length" style="font-size: 18px;">全选</a-checkbox>

            </div>
            <div class="goods-list">
              <div v-for="item in getShoppingCart" :key="item.skuId" class="goods-item">
                <a-checkbox v-model:checked="item.check" @change="itemCheckFun($event, item)" />
                <div class="img-box">
                  <img v-lazy="item.image" alt="goods">
                </div>

                <div class="goods-info">
                  <div class="goods-info-item info-one db-mb10">
                    <span style="display: inline-block;width: 80%;">{{ item.title }}</span>
                    <span>¥ {{ item.totalPrice }}</span>
                  </div>
                  <div class="goods-info-item info-two db-mb10">
                    <div>{{ item.skuAttr[0] }}</div>
                    <div class="info-fun">
                      <img src="@/assets/images/icons/delete.png" class="db-mr16" alt="del" @click="delGoodsFun(item)">
                      <img v-if="!item.isCollect" src="@/assets/images/icons/icon-car-collect.png" alt="heart" @click="CollectionFun(item, true)">
                      <img v-if="item.isCollect" src="@/assets/images/icons/icon-car-collect-red.png" alt="heart-red" @click="CollectionFun(item, false)">
                    </div>
                  </div>
                  <div class="goods-info-item info-three">
                    <div><span>商品单价：</span><span>¥{{ item.price }}</span></div>
                  </div>
                  <div class="num-fun">
                    <MinusOutlined style="font-size: 12px;margin-left: 6px;cursor: pointer;" @click="changNumber('reduce', item)" />
                    <input :value="item.count" class="num-Input" @change="changeInputFun($event, item, item.count)">
                    <PlusOutlined style="font-size: 12px;margin-right: 6px;cursor: pointer;" @click="changNumber('add', item)" />
                  </div>
                </div>
              </div>
              <div v-if="!getShoppingCart.length" style="height: 400px;padding-top: 100px;">
                <a-empty :description="'暂无商品，请选择商品添加购物车'" />
              </div>
            </div>

          </div>
        </a-spin>

        <div class="settlement">
          <div class="top-title">商品结算</div>
          <div class="settlement-content">
            <div class="settlement-item">
              <span class="settlement-item-name">商品小计</span>
              <span class="settlement-item-value">¥ {{ getTotalAmount }}</span>
            </div>
            <!-- <div class="settlement-item">
              <span class="settlement-item-name">促销优惠</span>
              <span class="settlement-item-value">-¥ 0.00</span>
            </div>
            <div class="settlement-item" style="color: #3B6652;font-weight: bold;">
              <span class="settlement-item-name">会员优惠</span>
              <span class="settlement-item-value">-¥ 0.00</span>
            </div> -->
          </div>
          <div class="total">
            <div class="total-price">
              <span>商品合计</span>
              <span class="all-price">¥ {{ getTotalAmount }}</span>
            </div>
            <!-- <div class="total-Tips">
              <ExclamationCircleOutlined />
              <span class="db-ml8">结算价格不含运输费</span>
            </div> -->
          </div>
          <button class="total-btn" @click="toSettle">立即结算</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  MinusOutlined,
  PlusOutlined
} from '@ant-design/icons-vue'
import { getCartList, deleteItem, checkItem } from '@/api/car'
import { collectSku } from '@/api/goods'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Car',
  components: {
    MinusOutlined,
    PlusOutlined
  },
  data() {
    return {
      AllCheck: false,
      checked: false,
      inputNumber: 1,
      spinning: false
    }
  },
  computed: {
    ...mapGetters({
      getIsAllCheck: 'car/getIsAllCheck',
      getShoppingCart: 'car/getShoppingCart',
      getTotalAmount: 'car/getTotalAmount'
    }),
    isAllCheck: {
      get() {
        return this.getIsAllCheck
      },
      set(val) {
        return this.checkAll(val)
      }
    }
  },
  created() {
    this.getCartListFun()
  },
  methods: {
    ...mapActions({
      setShoppingCart: 'car/setShoppingCart',
      checkAll: 'car/checkAll',
      changeItemCountFun: 'car/changeItemCountFun',
      delShoppingCart: 'car/delShoppingCart'
    }),
    CollectionFun(item, type) {
      // console.log(item)
      const data = {
        skuId: item.skuId
      }
      this.collectSkuFun(data)
      item.isCollect = !item.isCollect
    },
    collectSkuFun(data) {
      collectSku(data).then(res => {
        // console.log(res)
        // this.$message.success('收藏成功')
      })
    },
    itemCheckFun(e, item) { // 点击选择框
      const data = {
        skuId: item.skuId,
        check: item.check ? 1 : 0
      }
      this.checkItemFun(data)
    },
    changNumber(type, item) { // 修改购物车商品数量 + -
      if (type === 'add') {
        item.count = ++item.count
      } else {
        if (item.count === 1) {
          item.count = 1
        } else {
          item.count = --item.count
        }
      }
      const data = {
        skuId: item.skuId,
        num: item.count
      }
      this.changeItemCountFun(data)
    },
    changeInputFun(e, item, val) { // 修改购物车商品数量
      if (Number(e.target.value)) {
        item.count = Number(e.target.value)
      } else {
        e.target.value = val
      }
      const data = {
        skuId: item.skuId,
        num: e.target.value
      }
      this.changeItemCountFun(data)
    },
    delGoodsFun(item) { // 删除商品
      const _this = this
      this.$confirm({
        title: '您确定移出该商品？',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        centered: true,
        onOk() {
          const data = {
            skuId: item.skuId,
            num: item.count
          }
          deleteItem(data).then(res => {
            _this.delShoppingCart(data)
          })
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    toSettle() { // 跳转订单结算
      if (!this.getShoppingCart.filter(item => item.check).length) {
        this.$message.error('请选择商品进行结算')
        return
      }
      this.$router.push({ path: '/order/orderSettle' })
    },
    getCartListFun() { // 获取购物车列表
      this.spinning = true
      getCartList().then(res => {
        this.setShoppingCart(res.data).then(r => {
          if (r) this.spinning = false
        })
      })
    },
    checkItemFun(data) { // 按钮选中
      checkItem(data).then(res => {
        // console.log(res)
      })
    }
    // checkAllItemsFun(data) { // 按钮选中
    //   checkAllItems(data).then(res => {
    //     // console.log(res)
    //   })
    // }
  }
}
</script>
<style lang='less' scoped>
.car {
  padding: 64px 0;

  .car-title {

    margin-bottom: 64px;

    & > h3 {
      margin: 0;
      color: #333333;
      font-weight: bold;
      font-size: 36px;
    }
  }

  .shopping-car {
    display: flex;

    .top-title {
      margin-bottom: 32px;
      color: #333333;
      font-weight: bold;
      font-size: 18px;
    }

    .goods-list {
      width: 900px;
      min-height: 600px;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
      margin-right: 24px;

      .goods-item {
        width: 100%;
        height: 288px;
        border-bottom: 1px solid #F3F3F3;
        padding: 64px 32px 64px 16px;
        display: flex;
        align-items: flex-start;

        &:last-child {
          border-bottom: 0;
        }

        .img-box {
          width: 160px;
          height: 160px;
          margin: 0 17px;

          & > img {
            width: 100%;
            height: 100%;
          }
        }

        .goods-info {
          flex: 1;

          .goods-info-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #666666;
            font-weight: 400;
          }
          .info-one {
            color: #333333;
            font-size: 16px;
            font-weight: bold;
          }
          .info-two {

            .info-fun {

              & > img {
                width: 24px;
                height: 24px;
                cursor: pointer;
              }
            }
          }
          .info-three {
            margin-bottom: 16px;
          }
        }
      }
    }

    .settlement {
      width: 356px;
      height: 500px;

      .settlement-content {
        border-bottom: 1px solid #F3F3F3;

        .settlement-item {
          margin-bottom: 48px;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 400;
        }
      }
    }

    .total {
      padding: 48px 0;

      .total-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333333;
        font-weight: 400;
        margin-bottom: 16px;

        .all-price {
          font-weight: bold;
          font-size: 20px;
        }
      }
      .total-Tips {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .total-btn {
      width: 356px;
      height: 64px;
      background-color: #3B6652;
      color: #fff;
      text-align: center;
      line-height: 48px;
      border-radius: 33px;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .num-fun {
    width: 110px;
    height: 32px;
    border: 1px solid #333333;
    border-radius: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-input {
    border: none;
    outline: none;
    box-shadow: none;
  }
  .num-Input {
    text-align: center;
    width: 60px;
    font-weight: bold;
  }
}

</style>
